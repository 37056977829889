
import { Vue, Component, Prop } from "vue-property-decorator";
import Requisitos from "@/components/Requisitos/Requisitos.vue";

@Component({
  components: {
    Requisitos
  }
})
export default class RequisitosView extends Vue {
  private mounted() {}
}
