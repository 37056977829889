
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}
@Component
export default class Home extends Vue {
  private aBanners: Array<dataBanner> = [];
  private slide = 0;
  private slideIndex: number = 1;

  get footerStyle() {
    let result = "";
    if (this.$q.screen.xs) {
      result = "margin-top: 40px; margin-bottom: 40px;";
    } else {
      result = "margin-top: 30px; margin-bottom: 30px;";
    }
    return result;
  }

  private mounted() {
    this.getBanners();
    this.autoscroll();
  }
  private updated() {
    this.showSlides(this.slideIndex);
  }

  private toLink(link) {
    if (link != "#") {
      window.open(link, "_blank");
    }
  }

  private getBanners() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios("slider")
      .then(res => {
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen =
            "https://media.puntaarenas.cl/" + this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              "https://media.puntaarenas.cl/" +
              this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile = this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }

  private irRequisitos() {
    this.$router.push({ name: "Requisitos" });
  }

  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 5000);
  }
  // Fin Funciones del banner
}
