
import { Vue, Component, Prop } from "vue-property-decorator";
import Socios from "@/components/Socios/Socios.vue";

@Component({
  components: {
    Socios
  }
})
export default class SociosView extends Vue {
  private mounted() {}
}
